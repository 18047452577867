
import { Options, Prop, Vue, Watch } from "vue-property-decorator";

@Options({
  components: {},
})
export default class FreeText extends Vue {
  @Prop()
  public question!: any;
  @Prop()
  public showCorrectAnswers!: any;
  private answers: Array<any> = [];
  private textSlices: Array<string> = [];
  private inputFields: Array<any> = [];

  private onAnswerChange(value: string, position: any): void {
    const val = value.trim();
    const answer = this.answers.find((a: any) => a.position == position);
    // TODO code duplication
    // Due to the problem of answering 1,6 as 1,60 or 1.60 which was being marked as false!
    if (this.isNumeric(answer.value) && this.isNumeric(val)) {
      answer.correct = parseFloat(answer.value.replace(",", ".")) === parseFloat(val.replace(",", "."));
    }
    else {
      answer.correct = answer.value == val;
    }
    this.showSolutionButton();
  }

  // TODO code duplication
  public isAnswerCorrect(): boolean {
    return this.answers.findIndex((a: any) => !a.correct) === -1;
  }

  // TODO code duplication
  public isNumeric(str: string): boolean {
    return /^-?\d*\.?\d+(,\d+)?$/.test(str.replace(/,/g, '.'));
  }

  // TODO code duplication
  private showSolutionButton(): void {
    this.$emit("onShowSolutionButton");
  }

  // TODO code duplication
  @Watch("showCorrectAnswers", { immediate: true, deep: true })
  public onShowCorrectAnswers(toggle: any): void {
    if (true === toggle) {
      this.$emit("onAnswer", this.isAnswerCorrect());
    }
  }

  @Watch("question", { immediate: true, deep: true })
  public onQuestionChange(question: any): void {
    if (question) {
      const regex = /\{[0-9]+\}/g;
      this.textSlices = question.questionPattern.split(regex);
      this.inputFields = question.questionPattern.match(regex);
      this.inputFields =
        this.inputFields?.map((iField: any, index: number) => {
          return { index: index, value: "" };
        }) || [];
      const answerPositions = question.correctAnswer.split(";");
      this.answers = answerPositions.map((a: any, index: number) => {
        return {
          position: index,
          value: a,
          correct: false,
        };
      });
    }
  }
}
